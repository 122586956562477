
import { Options, Vue } from 'vue-class-component';
import { orderModule } from '@/modules/order/orderModule.vuex-module';
import ActiveOrder from '@/modules/order/common/classes/ActiveOrder.class';
import { Ticket } from '@/common/types/Ticket.type';
import { apiService } from '@/services/api.service';
import { Status } from '@/common/enums/status.enum';
import { Time } from '@/common/models/Time.model';

@Options({
	name: 'OrderLanding',
	components: {},
})
export default class OrderLanding extends Vue {
	get ticketUrl(): string | null {
		if (this.order?.ticketUUID) {
			const kioskUrl = (window as any).config.KioskUrl ?? window.location.origin;

			const qrCodeSize = 240;
			var payload = `w;${this.order.ticketUUID}`;

			return `${kioskUrl}/api/qr?s=${qrCodeSize}&p=${payload}`;
		}
		return null;
	}
	get balanceClass() {
		return this.balance < 0 ? 'green' : 'red';
	}
	get type() {
		return this.balance < 0 ? 'credit' : 'due';
	}
	get activityStatuses(): Map<string, Time[]> {
		var times: Time[] = [];
		var results: Map<string, Time[]> = new Map<string, Time[]>();
		let allTimes: Time[] =
			this.order?.activities.reduce((prev, current) => {
				return prev.concat(current.SelectedTimes);
			}, times) ?? [];
		allTimes.forEach((time) => {
			var status: string = this.stringForStatus(time.OrderStatusKey);
			var times: Time[] = results.get(status) ?? [];
			times.push(time);
			results.set(status, times);
		});
		return results;
	}
	get balance(): number {
		return orderModule._activeOrder?.balance ?? 0;
	}
	linkTo(time: Time): void {
		if (time.OrderEntityTimeKey && time.OrderStatusKey == Status.Upcoming) this.$router.push({ name: 'OrderTime', params: { orderEntityTimeKey: time.OrderEntityTimeKey } });
		else {
			console.error('No OrderEntityTimeKey');
		}
	}
	stringForStatus(status: Status): string {
		switch (status) {
			case Status.Upcoming:
				return 'Upcoming';
			case Status.Cancelled:
				return 'Cancelled';
			case Status['Checked In']:
				return 'Checked In';
			case Status.Complete:
				return 'Complete';
			case Status['No Show']:
				return 'No Show';
			default:
				return 'None';
		}
	}
	get order(): ActiveOrder | null {
		return orderModule._activeOrder;
	}
	get customerName(): string | undefined {
		if (this.order?.customerInfo.FirstName && this.order?.customerInfo.LastName) {
			return this.order?.customerInfo.FirstName + ' ' + this.order?.customerInfo.LastName;
		} else {
			return this.order?.customerInfo.LastName ?? this.order?.customerInfo.FirstName ?? 'No Customer Info';
		}
	}
}
